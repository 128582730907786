/* @flow */

import connectToParent from 'penpal/lib/connectToParent';
import connectToChild from 'penpal/lib/connectToChild';

import {Routing} from 'nutshell-core/routing';

(function startup() {
    const Nutmeg = {
        childConnection: null,

        getChildDomain: function() {
            return window.location.hostname.indexOf('kojo.zone') >= 0 ? 'kojo.zone' : 'nutshell.com';
        },

        getParentDomain: function() {
            var domain = Nutmeg.getChildDomain();
            if (domain === 'kojo.zone') {
                domain = 'app.testing.kojo.zone';
            } else {
                domain = 'www.nutshell.com';
            }

            return domain;
        },

        getIframe: function() {
            let iframe = document.getElementsByClassName('nutmeg-iframe')[0];

            if (iframe === undefined) {
                iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.className = 'nutmeg-iframe';
                iframe.src = 'https://app.' + Nutmeg.getChildDomain() + '/nutmeg.html';
                if (document.body) {
                    document.body.appendChild(iframe);
                }
            }

            return iframe;
        },

        getChildConnection: function() {
            if (Nutmeg.childConnection === null) {
                const iframe = Nutmeg.getIframe();

                // Connect iFrame to app.nutshell.com
                Nutmeg.childConnection = connectToChild({
                    // The iframe to which a connection should be made
                    iframe: iframe,
                });
            }

            return Nutmeg.childConnection;
        },

        // This function is safe to call pre/post DOMContentLoaded, and from app or dotcom
        trackUrl: function() {
            // This is the function we'll call once the DOM is ready to have iframes attached
            const callback = function() {
                // Grab all the data we want for nutmeg
                var deserializedPageData = {
                    _path: window.location.pathname,
                    _timestamp: Math.round(new Date().getTime() / 1000),
                };

                var urlParams = Nutmeg.nutshell_getUrlParams();
                if (urlParams) {
                    deserializedPageData = {
                        ...deserializedPageData,
                        ...urlParams,
                    };
                }


                if (window.location.hostname.indexOf('app.kojo.zone') >= 0 || window.location.hostname.indexOf('app.nutshell.com') >= 0) {
                    // trackUrl was called from app instead of dotcom, so we can just push directly to nutmeg
                    deserializedPageData = {
                        _fromApp: true,
                        ...deserializedPageData,
                    }

                    Nutmeg.nutshell_trackUrl(deserializedPageData);
                } else {
                    // Call app.nutshell.com's exposed method `trackPageData`
                    // with our pageData. This sends nutmeg data to
                    // the iframe that lives on app.nutshell.com
                    const childConnection = Nutmeg.getChildConnection();

                    childConnection.promise.then(function (child) {
                        child.trackPageData(deserializedPageData);
                    });
                }
            }

            if (document.readyState !== 'loading') {
                // Ready to call immediately
                callback();
            } else {
                // Listen for DOM loaded event and call then
                document.addEventListener('DOMContentLoaded', callback);
            }
        },

        // Penpal considers the actual iframe the "child".
        // In our case, this lives on app.nutshell.com and connects
        // to its "parents", i.e. nutshell.com
        connectToParent: function() {
            var connection = connectToParent({
                parentOrigin: 'https://' + Nutmeg.getParentDomain(),
                // Methods app.nutshell.com is exposing to dotcom / consumers
                methods: {
                    trackPageData(pageData) {
                        Nutmeg.nutshell_trackUrl(pageData);
                    },
                },
            });

            return connection;
        },

        /**
         * Get the current pages URL params as POJO. null if none.
         * @return {object|null} [description]
         */
        nutshell_getUrlParams: function() {
            if (!window.location.search.length) return null;
            return Routing.deparam(window.location.search.split('?')[1]);
        },

        /**
         * Called on every page load, this function parses the url and deserializes
         * it into an object containing the path, timestamp, and any utm params contained
         * in that URL.
         *
         * If data from previous page loads exists, new data onto that array and re-written
         * back into localStorage.
         *
         * If for some reason localStorage can not be written to, we'll fall back to
         * writing our first page visit that contains utm tags into a Cookie.
         *
         */
        nutshell_trackUrl: function(deserializedPageData) {
            // our key is 'nutmeg' - not sure we want to global this thing
            var existingLocalData = Nutmeg.readNutshellObject('nutmeg'),
                newUrlArray = [];

            // if we have existing data, use that..
            if (existingLocalData) {
                newUrlArray = existingLocalData;
            }

            // tack on our new page data to the array, and shove that back into
            // localStorage
            newUrlArray.push(deserializedPageData);

            // if we can write to localStorage, send it all!
            if (Nutmeg.canWriteToLocalStorage()) {
                Nutmeg.writeNutshellObject('nutmeg', newUrlArray);

                // otherwise, if we can't write to localStorage, loop through our existing
                // array of data and find the first entry that contains any utm tags. If
                // we find one, write that (knowing it will fail writing to localStorage
                // and fallback to writing to a Cookie). If we don't have any utm data,
                // don't do anything.
            } else if (newUrlArray.length) {
                var hasFoundEntryWithUtmTags = false;
                newUrlArray.forEach(function(pageData) {
                    if (Nutmeg.pageDataHasUtmTags(pageData) && !hasFoundEntryWithUtmTags) {
                        Nutmeg.writeNutshellObject('nutmeg', [pageData]);
                        hasFoundEntryWithUtmTags = true;
                    }
                });
            }
        },

        /**
         * Store a value in either localStorage or in cookies. If neither are available,
         * then this is a no-op, and the data is lost.
         *
         * @param {string} key
         * @param {string} data
         * @returns {boolean}
         */
        writeNutshellValue: function(key, data) {
            try {
                localStorage.setItem(key, data);
                return true;
            } catch (e) {
                console.log('Unable to save to localstorage, falling back to cookie: ' + key);
            }
        },

        /**
         * Wrapper function around our localStorage writer - expects
         * an object and it'll do the work to turn that into stringify'd JSON
         * @param  {String} key                    desired localStorage key to write to
         * @param  {object|array} objectData     data to stringify
         * @return void
         */
        writeNutshellObject: function(key, objectData) {
            Nutmeg.writeNutshellValue(key, JSON.stringify(objectData));
        },

        /**
         * Checks to see if localStorage can be successfully written and read
         * @return {Boolean}
         */
        canWriteToLocalStorage: function() {
            var test = '_nutshell_test';

            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
            } catch (e) {
                return false;
            }
        },

        /**
         * Reads a stored value from localStorage or cookies. If neither are available, this will return undefined.
         * @param key
         * @returns {string|undefined}
         */
        readNutshellValue: function(key) {
            try {
                if (localStorage) {
                    var localStorageValue = localStorage.getItem(key);
                    // allow fallback to cookie if we have a null value (i.e localstorage exists but wasn't writable)
                    if (localStorageValue !== null) return localStorageValue;
                }
            } catch (e) {
                console.log('Unable to read localstorage, falling back to cookie: ' + key);
            }
        },

        /**
         * Wrapper function around our localStorage reader, will parse whatever
         * it gets back from localStorage, or return nothing if that key does not
         * exist
         * @param  {String} key          desired key to read from localStorage
         * @return {object|array}     parsed string from localStorage
         */
        readNutshellObject: function(key) {
            var localStorageString = Nutmeg.readNutshellValue(key);

            if (localStorageString) {
                return JSON.parse(localStorageString);
            }
        },

        /**
         * Helper to see if an entry of page data has any utm tags.
         * Because utm tag keys are arbitrary, we have to check for the
         * number of keys in our page data, and if it's above 2, we know
         * we have extra stuff (aka utm tags)
         * @param  {object} deserializedPageData entry of page data
         * @return {Boolean}                     does this entry contain utm tags info
         */
        pageDataHasUtmTags: function(deserializedPageData) {
            if (!deserializedPageData) {
                return false;
            }

            return Object.keys(deserializedPageData).length > 2;
        },
    };

    window.Nutmeg = Nutmeg;
})();
